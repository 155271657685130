<template>
  <!-- 智能终端采集分析系统-->
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic1">
        <img class="img" :src="img1">
      </div>
      <div class="link1">
        <div class="title">
          <div class="title-big">产品概述</div>
          <div class="title-small">让您更好的了解我们整个产品</div>
        </div>
        <div class="form1">
          <div>智能终端采集分析系统是一套集智能手机采集、还原、分析为一体的案侦实战类应用软件。不仅解决“取”的问题，通过采用合理高效的数据提取与还原方式，来获取手机内各类基础及应用类信息。同时，也解决“用”的问题，通过以人、事、地、物、组织等各种核心要素为依据，采用清洗、提炼、关联、归并等各种数据分析方法，把数据用活，用好。</div>
          <br>
          <div>在数据采集方面，产品除支持对当前所有主流型号的智能终端进行数据提取之外，还重点提供<span class="color">【删除数据恢复】</span>功能及<span class="color">【云提取】</span>功能。随着网盘、云盘的发展，很多APP数据留存于云端，我司独立开发出了针对20多款APP的云数据静默提取（无需提供APP用户名、密码或二维码扫码登入）功能，进一步提升价值数据的获取。</div>
          <div class="pic2">
            <img class="img" :src="img2">
          </div>
          <div>在数据分析方面产品有极大的优势，不仅在单个手机的<span class="color">【分析维度】、【纵深】、【关联性】</span>方面极其细致、详尽；而且针对多个智能手机，提供各种维度的群体类、组织类分析，便于<span class="color">【挖掘群体特性】</span>的通联、行程、经济、内容等特征。</div>
          <div>另外，针对目前手机APP信息相对孤立的情况，本产品提供的“追影”功能可以有效的【关联所有APP的行为】；本产品提供详尽的经济类分析，可以从<span class="color">【对象】、【金额】、【关系】、【账号】、【日程】</span>等各种维度分析各种交易类经济信息；为了便于操作人员的数据溯源，本产品提供的所有分析功能结果可以<span class="color">【完美还原所有的原始操作行为】</span>，如社交、出行、订单等，大大节省证据固定时间。</div>
        </div>
      </div>
      <Reserve></Reserve>
      <div class="link2">
        <div class="title">
          <div class="title-big">产品概述</div>
          <div class="title-small">让您更好的了解我们整个产品</div>
        </div>
        <div class="forms2">
          <div class="form2">
            <div class="header">
              <div class="form2_icon">
                <img :src="img3">
              </div>
              <div class="form2_title">采集</div>
            </div>
            <div class="content">
              <div><span class="font">- 通用采集流程，</span>内部集成十余种提取方法，针对不同品牌、型号、版本的设备，内部自动筛选匹配出一种或多种采集方案并引导使用者进行提取，解决了使用者不知道用什么方法提取，能有效提取数据的困扰。</div>
              <br>
              <div><span class="font">- 智能数据监测，</span>提取过程中自动检测数据质量，在没有提取到或提取不完整时，自动提示用户使用备选采集方式或重试，能让用户及时发现异常并处理。</div>
              <br>
              <div><span class="font">- 碎文件高速提取</span>，针对移动设备存储文件多、文件小的特点，定制Android和IOS文件传输协议，比普通提取方式速度快30-50%，大幅度提高了采集效率。</div>
            </div>
          </div>
          <div class="form2">
            <div class="header">
              <div class="form2_icon">
                <img :src="img4">
              </div>
              <div class="form2_title">还原</div>
            </div>
            <div class="content">
              <div><span class="font">- APP还原率超同行业30%至40%，</span>支持人为删除记录恢复等，无时间限制，依赖于存储残留和覆盖情况。</div>
              <br>
              <div><span class="font">- 云端数据提取，</span>支持超过20款APP的云端数据获取，极大提升有效数据获取率（如饿了么、京东、滴滴打车、百度云盘等）。</div>
              <br>
              <div><span class="font">- APP关键数据深度提取，</span>提取各类APP获取位置信息、常用联系人信息、地址及经纬度信息。</div>
              <br>
              <div><span class="font">- 经济账云数据，</span>不同于同行业的仿真技术，产品采用独立研发的云提取技术自动从云端高效获取交易类APP的详细账单及支付记录（如微信、支付宝、12306等）。</div>
            </div>
          </div>
          <div class="form2">
            <div class="header">
              <div class="form2_icon">
                <img :src="img5">
              </div>
              <div class="form2_title">分析</div>
            </div>
            <div class="content">
              <div><span class="font">- 丰富的研判模型，</span>提供超过20类涉及通联、行为、内容等自动判定模型，快速挖掘手机内各类疑似异常行为。</div>
              <br>
              <div><span class="font">- 支持内容核心信息提取，</span>对各类社交类软件的详细内容进行多维度分析、提取、标签，便于用户快速查找、定位信息。</div>
              <br>
              <div><span class="font">- 完整经济行为回溯，</span>提供针对交易类APP账单的完美回溯，可以知道每笔账交易的对象、原因、以及地理位置，并扩展分析该行为前后的其他APP关联行为。</div>
              <br>
              <div><span class="font"> 多形式多人/单人多维度完整关系建模，</span>有效分析高频联系人、联系密度、联系内容、联系时序、快速判定时空关联性。</div>
              <br>
              <div><span class="font">- 多人多机时序行为分析，</span>可以有效判定单人或多人的行为规律及行为规律伴随产生的经济行为、聊天内容、浏览记录、线下出没等。</div>
            </div>
          </div>
          <Reserve></Reserve>
        </div>
      </div>
      <div class="link1">
        <div class="title3">
          <div class="title3-big">应用场景</div>
          <div class="title3-small">以下是对复杂关系、海量内容等实际场景的举例介绍</div>
        </div>
        <div class="forms3" v-for="(form, index) in forms3" :key="index">
          <div class="form3">
            <div class="form3_icon">
              <img class="img" :src="form.img">
            </div>
            <div class="form3_content">
              <div class="form3_title">{{form.title}}</div>
              <div class="form3_text">{{form.text}}</div>
            </div>
          </div>
        </div>
      </div>
      <Reserve></Reserve>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Reserve from '../components/reserve.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page4',
  components: {
    Header,
    Reserve,
    Record,
    Footer
  },
  data () {
    return {
      img1: require('@/assets/img/page4.1.1.png'),
      img2: require('@/assets/img/page4.1.2.png'),
      img3: require('@/assets/img/page4.2.1.png'),
      img4: require('@/assets/img/page4.2.2.png'),
      img5: require('@/assets/img/page4.2.3.png'),
      forms3: [{
        img: require('@/assets/img/page4.3.1.png'),
        title: '刑侦场景',
        text: '通过涉案人员的手机信息，可以获取案发前后涉案人员的出行记录，以及通联特征、敏感内容信息；支持分析案发前后的通讯密度了解同案人员；支持分析收入情况解析经济关联分析涉案行为。'
      }, {
        img: require('@/assets/img/page4.3.2.png'),
        title: '缉毒场景',
        text: '通过软件分析涉案人员经常出没区域，掌握是否存在高频涉毒区域出没规律或涉毒行为规律；掌握区域出没前后手机上所有的社交行为，以及各类资金账户的变化情况，了解资金交易往来规律及原因；对涉毒群体的共同高频通联对象、高频资金往来对象进行集中分析，进一步研判挖掘。'
      }, {
        img: require('@/assets/img/page4.3.3.png'),
        title: '治安场景',
        text: '通过分析各类社交软件的通联特征、传播途径，结合交易过程中的交易地点、交易金额、交易对象等情况快速确认涉案行为，从而提升效率。'
      }, {
        img: require('@/assets/img/page4.3.4.png'),
        title: '经侦场景',
        text: '通过智能提取方式可以快速掌握涉案人员主要活动的银行账号、交易类APP账号，并且可以详细了解每个账号的收入、支出、流水、差额等信息，快速了解资金走向；支持分析单个、多个手机对象的主要经济往来对象，了解经济往来的金额、频次、规律等；支持分析账款事由，掌握大笔、高频进出账的原因。'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.pic1 {
  height: 2.02rem;
  margin-bottom: 0.16rem;
}
img {
 width: 100%;
}
.color {
  color: #EE602C;
}
.link1 {
  text-align: center;
  .title {
    margin-bottom: 0.16rem;
    .title-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .title3 {
    margin-bottom: 0.16rem;
    margin-top: 0.2rem;
    .title3-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title3-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .form1 {
    margin: 0 0.16rem 0 0.16rem;
    padding: 0.12rem 0.12rem 0.14rem 0.12rem;
    border-radius: 0.02rem;
    box-shadow: 0 0.01rem 0.07rem 0 rgba(0, 0, 0, 0.11);
    font-family: PingFangSC-Medium;
    text-align: start;
    color: #333333;
    .pic2 {
      width: 2.775rem;
      margin: 0.16rem auto;
    }
  }
  .form3 {
    border-radius: 0.02rem;
    box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
    margin: 0 0.16rem 0.08rem 0.16rem;
    display: flex;
    .form3_icon {
      width: 1.065rem;
      margin: 0.09rem 0.12rem 0.09rem 0.07rem;
    }
    .form3_content {
      width: 2.5rem;
      text-align: start;
      color: #333333;
      margin: 0.09rem 0.12rem 0.04rem 0;
      .form3_title {
        font-size: 0.14rem;
        font-family: PingFangSC-Medium;
        margin-bottom: 0.02rem
      }
      .form3_text {
        font-size: 0.11rem;
        font-family: PingFangSC-Regular
      }
    }
  }
}
.link2 {
  background-color: #f7f7f7;
  text-align: center;
  padding-bottom: 0.01rem;
  .title {
    margin-bottom: 0.16rem;
    padding-top: 0.2rem;
    .title-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .form2 {
    border-radius: 0.02rem;
    background-color: #ffffff;
    box-shadow: 0 0.01rem 0.07rem 0 rgba(0, 0, 0, 0.11);
    margin: 0 0.16rem 0.08rem 0.16rem;
    padding: 0.12rem;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 0.16rem;
      .form2_icon {
        width: 0.2rem;
        padding-right: 0.08rem;
      }
      .form2_title {
        color: #333333;
        font-family: PingFangSC-Medium;
        font-size: 0.14rem;
      }
    }
    .content {
      text-align: start;
      color: #666666;
      .font {
        font-family: PingFangSC-Medium;
        color: #333333;
      }
    }
  }
}
</style>
